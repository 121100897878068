import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Label,
} from "reactstrap";
import { getOrders as onGetOrders } from "store/actions";
import ViewICon from "./../../assets/icons/view-icon.svg";
import Select from "react-select";
import { getOrderResultAllData } from "../../service/orderResultService";
//redux
import {
  orderServiceGroup,
  orderStatusGroup,
} from "components/Common/DropdownGroup";
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "pages/Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import moment from "moment";
import { Spinner } from "reactstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { ImCross } from "react-icons/im";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { DebounceInput } from "react-debounce-input";
import Multiselect from "multiselect-react-dropdown";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';



// import { addDays } from "date-fns";
// import { DateRangePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file

const ResultTable = (props) => {
  const dispatch = useDispatch();
  const [selectedGroup, setselectedGroup] = useState(null);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [backUpdata, setBackUpdata] = useState([]);
  const [orderfilter, setOrderfilter] = useState({});
  const [saveDelId, setSaveDelUId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fliedSearcg, setFliedSearch] = useState("");
  const [orderStatus, setOrderStatus] = useState(null);
  const [orderStatusTwo, setOrderStatusTwo] = useState("");
  const [randomNo, setRandomNo] = useState("");
  const [serviecName, setServiceName] = useState(null);
  const [orderId, setOrderId] = useState();
  const [cus_name, setCusName] = useState();
  const [transactionId, settransactionId] = useState();
  const [valueDatePicker, setValueDatePicker] = useState([null, null]);
  const [serviecNameOnly, setServiceNameOnly] = useState([]);
  const [fromdate, setFromdate] = useState("")
  const [todate, setTodate] = useState("")
  const [visibleActions, setVisibleActions] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [optionData, setOptionData] = useState([
    {
      label: "Anti-Catfish Check",
      value: "Anti-Catfish",
    },
    { label: "Criminal Check", value: "Criminal" },
    { label: "Social Check", value: "Social" },
  ]);
  const [checkmargin, setCheckMargin] = useState("165px");



  const [page, setPage] = useState("1");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startPage, setStartPage] = useState(1);

  const [countShow, setCountShow] = useState(0);
  const [limit, setlimit] = useState(10);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {

    if (orderfilter ||
      cus_name ||
      orderId ||
      transactionId ||
      orderStatusTwo || currentPage || todate) {
      getOrderAll();

    }

  }, [
    orderfilter,
    cus_name,
    orderId,
    transactionId,
    orderStatusTwo, currentPage,
    todate, limit
  ]);

  const { orders } = useSelector((state) => ({
    orders: state.ecommerce.orders,
  }));


  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  useEffect(() => {
    dispatch(onGetOrders());
  }, [dispatch]);

  const selectRow = {
    mode: "none",
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orderData.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const toggleViewModal = () => setModal1(!modal1);

  const handleOrderCheck = (type, data) => {
    if (data.order[0].service_name?.toLowerCase().includes("anti-cashfish")) {
    }
    return <ImCross />;
  };
  const handlePageSizeChange = (selectedOption) => {
    setPageSize(selectedOption.value);
    setlimit(selectedOption.value);
    setCurrentPage(1); // Reset to the first page
    setStartPage(1); // Reset startPage for navigation
  };
  // const toggleActions = async (orderId) => {
  //   // const resp = await getLumenChat(orderId);
  //   // console.log("Toggling actions for:", orderId);
  //   setVisibleActions((prev) => (prev == orderId ? null : orderId));
  // };


  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (!event.target.closest(".action-menu") && !event.target.closest(".action-dots")) {
  //       setTimeout(() => setVisibleActions(null), 1000); // Slight delay to allow UI to update
  //     }
  //   };
  //   // handleClickOutside();
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [visibleActions]);


  const toggleActions = (orderId) => {
    setVisibleActions(prev => prev === orderId ? null : orderId);
  };

  // Add this useEffect to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".action-menu") && !event.target.closest(".action-dots")) {
        setVisibleActions(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  // const toggleActions = async (orderId, event) => {
  //   if (visibleActions === orderId) {
  //     setVisibleActions(null);
  //   } else {
  //     // Calculate position based on the clicked element
  //     const rect = event.currentTarget.getBoundingClientRect();
  //     setDropdownPosition({
  //       top: rect.top,
  //       left: rect.left - 150, // Position to the left of the dots
  //     });
  //     setVisibleActions(orderId);
  //   }
  // };

  // // Add this useEffect to handle clicks outside the dropdown
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (!event.target.closest(".action-menu") && !event.target.closest(".action-dots")) {
  //       setVisibleActions(null);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // // Add this useEffect to update dropdown position when scrolling
  // useEffect(() => {
  //   if (visibleActions) {
  //     const handleScroll = () => {
  //       // Find the current action dots element
  //       const actionDots = document.querySelector(`.action-dots[data-id="${visibleActions}"]`);
  //       if (actionDots) {
  //         const rect = actionDots.getBoundingClientRect();
  //         setDropdownPosition({
  //           top: rect.top,
  //           left: rect.left - 150,
  //         });
  //       }
  //     };

  //     window.addEventListener('scroll', handleScroll, true);
  //     return () => {
  //       window.removeEventListener('scroll', handleScroll, true);
  //     };
  //   }
  // }, [visibleActions]);


  const EcommerceOrderColumns = (toggleModal) => [
    {
      //payment_status

      dataField: "first_name",
      text: "FIRST NAME",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p> {row.first_name || "-"}</p>
            {row.first_name && row.payment_status == "success" && (
              <i
                className="bx bxs-check-circle svg-black me-1 ms-1"
                style={{
                  color: "#30E0A1",
                  fontSize: "18px",
                }}
              ></i>
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "last_name",
      text: "LAST NAME",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p> {row.last_name || "-"}</p>
            {row.last_name && row.payment_status == "success" && (
              <i
                className="bx bxs-check-circle svg-black me-1 ms-1"
                style={{
                  color: "#30E0A1",
                  fontSize: "18px",
                }}
              ></i>
            )}
          </div>
        </>
      ),
    },
    // {
    //   dataField: "processer",
    //   text: "PROCESSOR",
    //   sort: true,
    // },
    {
      dataField: "timesearch",
      text: "TIMES SEARCHED",
      sort: true,
    },
    {
      dataField: "updatedAt",
      text: "LAST CHECKED ON",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>{moment(row.updatedAt).format("MM/DD/YYYY")}</p>
          <p>{moment(row.updatedAt).format("hh:mm:ss A")}</p>
        </>
      ),
    },
    {
      dataField: "services",
      text: "SERVICE",
      formatter: (cellContent, row) => (
        <>
          {row?.services?.map((w, i) => {
            let checklength = row?.w?.length;
            return (
              <span key={i}>
                {w?.service_name || ""}
                {i >= 0 && i + 1 < checklength && " - "}
              </span>
            );
          })}
        </>
      ),
    },
    // {
    //   dataField: "action",
    //   isDummyField: true,
    //   text: "ACTION",
    //   formatter: (cellContent, row) => (
    //     <div className="action-menu-wrapper">
    //       <span
    //         className="action-dots"
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           toggleActions(row._id);
    //         }}
    //       >
    //         ⋮
    //       </span>
    //       {visibleActions === row._id && (
    //         <div className="action-menu">
    //           <Link
    //             to={`/search-results/details/${row._id}`}
    //             className="menu-item"
    //             title="View Details"
    //           >
    //             <i className="fas fa-eye"></i>
    //             <span>View</span>
    //           </Link>
    //           <Link
    //             to={`/order/Lumen-chat/${row._id}`}
    //             className="menu-item"
    //             title="Chat"
    //           >
    //             <i className="fas fa-comment"></i>
    //             <span>Lumen Chat</span>
    //           </Link>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },

    {
      dataField: "action",
      isDummyField: true,
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div className="action-menu-wrapper" style={{ position: "relative", display: "inline-block" }}>
          <span
            className="action-dots"
            onClick={(e) => {
              e.stopPropagation();
              toggleActions(row._id);
            }}
            style={{ cursor: "pointer" }}
          >
            ⋮
          </span>
          {visibleActions === row._id && (
            <div
              className="action-menu"
              style={{
                position: "absolute",
                top: "-100%",
                right: "20px", // Position to the right of the dots
                zIndex: "9999",
                background: "#fff",
                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                borderRadius: "4px",
                minWidth: "150px",
                padding: "5px 0",
                whiteSpace: "nowrap",
                width: "120px", // Smaller width
                padding: "2px 0", // Less padding
                marginTop: "5px", // Add some space between dots and dropdown
                border: "1px solid #eee" // Light border
              }}
            >
              <Link
                to={`/search-results/details/${row._id}`}
                className="menu-item"
                title="View Details"
                style={{ display: "block", padding: "6px 12px", textDecoration: "none", color: "#333" }}
              >
                <i className="fas fa-eye" style={{ marginRight: "8px" }}></i>
                <span>View</span>
              </Link>
              <Link
                to={`/order/Lumen-chat/${row._id}`}
                className="menu-item"
                title="Chat"
                style={{ display: "block", padding: "6px 12px", textDecoration: "none", color: "#333" }}
              >
                <i className="fas fa-comment" style={{ marginRight: "8px" }}></i>
                <span>Lumen Chat</span>
              </Link>
            </div>
          )}
        </div>
      ),
    },
    // {
    //   dataField: "action",
    //   isDummyField: true,
    //   text: "ACTION",
    //   formatter: (cellContent, row) => (
    //     <div className="action-menu-wrapper">
    //       <span
    //         className="action-dots"
    //         data-id={row._id} // Add this attribute to find the element later
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           toggleActions(row._id, e);
    //         }}
    //         style={{ cursor: "pointer" }}
    //       >
    //         ⋮
    //       </span>
    //       {visibleActions === row._id && (
    //         <div 
    //           className="action-menu" 
    //           style={{ 
    //             position: "fixed", 
    //             top: `${dropdownPosition.top}px`,
    //             left: `${dropdownPosition.left}px`,
    //             zIndex: "9999",
    //             background: "#fff",
    //             boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    //             borderRadius: "4px",
    //             minWidth: "150px",
    //             padding: "5px 0"
    //           }}
    //         >
    //           <Link
    //             to={`/search-results/details/${row._id}`}
    //             className="menu-item"
    //             title="View Details"
    //             style={{ display: "block", padding: "8px 15px", textDecoration: "none", color: "#333" }}
    //           >
    //             <i className="fas fa-eye" style={{ marginRight: "8px" }}></i>
    //             <span>View</span>
    //           </Link>
    //           <Link
    //             to={`/order/Lumen-chat/${row._id}`}
    //             className="menu-item"
    //             title="Chat"
    //             style={{ display: "block", padding: "8px 15px", textDecoration: "none", color: "#333" }}
    //           >
    //             <i className="fas fa-comment" style={{ marginRight: "8px" }}></i>
    //             <span>Lumen Chat</span>
    //           </Link>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },

  ];

  useEffect(() => {
    if (orders && !orders.length) {
      onGetOrders();
    }
  }, [onGetOrders, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    setModal(!modal);
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  // useEffect(async () => {
  //   setLoading(true);
  //   let chackDat = calculateDateIso();
  //   const resp = await getOrderResultAllData(chackDat);
  //   if (resp.code == 200) {
  //     setBackUpdata(resp.data);
  //     // setOrderData(resp.data);

  //     handleApiFetchdata(resp.data);
  //   } else {
  //     setLoading(false);
  //     setCheckMargin("0px");
  //   }
  // }, [
  //   orderfilter,
  //   cus_name,
  //   orderId,
  //   transactionId,
  //   orderStatusTwo,
  //   serviecNameOnly?.length,
  // ]);
  const handleApiFetchdata = (data) => {

    setOrderData(data);
    setLoading(false);
    setCheckMargin("0px");

    // let arr = data;
    let dataCheck = localStorage.getItem("search_filter") || {};


    // var checkFilter = null;
    // if (Object.keys(dataCheck)?.length > 0) {
    //   checkFilter = JSON?.parse(dataCheck);
    // } else {
    //   checkFilter = dataCheck;
    // }

    // if (checkFilter?.cus_name) {
    //   let dataone = arr?.filter((w) => {
    //     return (
    //       w?.first_name
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase()) ||
    //       w?.last_name
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase()) ||
    //       w?.phone
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase()) ||
    //       w?.email
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase()) ||
    //       w?.user_id
    //         ?.toLowerCase()
    //         .includes(checkFilter?.cus_name?.toLowerCase())
    //     );
    //   });
    //   arr = dataone;
    // }

    // if (checkFilter?.orderId) {
    //   let datatwo = arr?.filter((w) => {
    //     return w._id.includes(checkFilter?.orderId);
    //   });
    //   arr = datatwo;
    // }

    // if (
    //   checkFilter?.serviecNameOnly &&
    //   checkFilter?.serviecNameOnly?.length > 0
    // ) {
    //   let checkdetails = checkFilter?.serviecNameOnly?.map((w) => w.value);
    //   let datafour = [];
    //   arr.map((w) => {
    //     let arrCheck = null;

    //     if (
    //       checkdetails[0] &&
    //       checkdetails?.length == 1 &&
    //       w.order.length == 1
    //     ) {
    //       if (
    //         w?.order[0]?.service_name
    //           ?.toLowerCase()
    //           .includes(checkdetails[0].toLowerCase())
    //       ) {
    //         arrCheck = w;
    //       }
    //     }

    //     if (
    //       checkdetails[1] &&
    //       checkdetails?.length == 2 &&
    //       w.order.length == 2
    //     ) {
    //       if (
    //         (w?.order[0]?.service_name
    //           ?.toLowerCase()
    //           .includes(checkdetails[0].toLowerCase()) &&
    //           w?.order[1]?.service_name
    //             ?.toLowerCase()
    //             .includes(checkdetails[1].toLowerCase())) ||
    //         (w?.order[0]?.service_name
    //           ?.toLowerCase()
    //           .includes(checkdetails[1].toLowerCase()) &&
    //           w?.order[1]?.service_name
    //             ?.toLowerCase()
    //             .includes(checkdetails[0].toLowerCase()))
    //       ) {
    //         arrCheck = w;
    //       }
    //     }
    //     if (
    //       checkdetails[2] &&
    //       checkdetails?.length == 3 &&
    //       w.order.length == 3
    //     ) {
    //       arrCheck = w;
    //     }
    //     if (arrCheck !== null) {
    //       datafour.push(arrCheck);
    //     }
    //   });

    //   arr = datafour;
    // }

    // if (
    //   checkFilter?.orderStatusTwo &&
    //   checkFilter?.orderStatusTwo?.value !== "all Statuses"
    // ) {
    //   let dataThree = arr?.filter((w) => {
    //     return (
    //       w?.order[0]?.order_status
    //         ?.toLowerCase()
    //         ?.includes(checkFilter?.orderStatusTwo?.value?.toLowerCase()) ||
    //       w?.order[1]?.order_status
    //         ?.toLowerCase()
    //         ?.includes(checkFilter?.orderStatusTwo?.value?.toLowerCase()) ||
    //       w?.order[2]?.order_status
    //         ?.toLowerCase()
    //         ?.includes(checkFilter?.orderStatusTwo?.value?.toLowerCase())
    //     );
    //   });
    //   arr = dataThree;
    // }
    // if (transactionId) {
    //   let dataThree = arr?.filter((w) => {
    //     return w?.transaction_id?.includes(transactionId);
    //   });
    //   arr = dataThree;
    // }


  };



  const calculateDateIso = () => {
    let dataCheck = localStorage.getItem("search_filter") || {};
    var checkFilter = null;
    if (Object.keys(dataCheck)?.length > 0) {
      checkFilter = JSON?.parse(dataCheck);
    } else {
      checkFilter = dataCheck;
    }
    if (checkFilter?.orderfilter && checkFilter.orderfilter?.length > 0) {
      const one = new Date(checkFilter.orderfilter[0]).toISOString();
      const two = new Date(checkFilter.orderfilter[1]).toISOString();
      return {
        to: one,
        from: two,
      };
    } else {
      return {};
    }
  };

  const getOrderAll = async (pageNo) => {
    let dataBody = {
      "orderId": orderId ? orderId : "",
      "orderStatus": orderStatusTwo ? orderStatusTwo : "",
      "to": todate ? todate : "",
      "from": fromdate ? fromdate : "",
      "flied": cus_name ? cus_name : "",
      "transactionId": transactionId ? transactionId : "",

      "page": currentPage,
      "limit": limit
    };
    setLoading(true);
    const resp = await getOrderResultAllData(dataBody);

    if (resp.code == 200) {
      handleApiFetchdata(resp.data);
      setCountShow(resp.count)
      setData(resp.data);
      setTotalPages(resp.totalPages);

    } else {
      setLoading(false);
      setCheckMargin("0px");
    }
  };

  // const handleSetLocalFilterItem = () => {
  //   let dataCheck = localStorage.getItem("search_filter") || {};
  //   var checkFilter = null;
  //   if (Object.keys(dataCheck)?.length > 0) {
  //     checkFilter = JSON?.parse(dataCheck);
  //   } else {
  //     checkFilter = dataCheck;
  //   }

  //   if (checkFilter?.orderfilter && checkFilter.orderfilter?.length > 0) {
  //     setValueDatePicker(checkFilter?.orderfilter);
  //     const one = new Date(checkFilter.orderfilter[0]).toISOString();
  //     const two = new Date(checkFilter.orderfilter[1]).toISOString();
  //     setOrderfilter({
  //       ...orderfilter,
  //       to: one,
  //       from: two,
  //     });
  //   }
  //   if (checkFilter?.cus_name) {
  //     setCusName(checkFilter?.cus_name);
  //   }
  //   if (checkFilter?.orderId) {
  //     setOrderId(checkFilter.orderId);
  //   }
  //   if (checkFilter?.transactionId) {
  //     settransactionId(checkFilter?.transactionId);
  //   }
  //   if (
  //     checkFilter?.orderStatusTwo &&
  //     Object.keys(checkFilter?.orderStatusTwo)?.length > 0
  //   ) {
  //     setOrderStatus(checkFilter.orderStatusTwo);
  //     setOrderStatusTwo(checkFilter.orderStatusTwo.value);
  //   }
  //   if (
  //     checkFilter?.serviecNameOnly &&
  //     checkFilter?.serviecNameOnly?.length > 0
  //   ) {
  //     setServiceNameOnly(checkFilter?.serviecNameOnly);
  //   }
  // };

  const handlehSetFilter = (name, data) => {
    let dataCheck = localStorage.getItem("search_filter") || "{}";
    var checkFilter = JSON?.parse(dataCheck);

    let makeSetData = {
      orderfilter: checkFilter?.orderfilter || {},
      orderfiltert: checkFilter?.orderfiltert || {},
      cus_name: checkFilter?.cus_name || "",
      orderId: checkFilter?.orderId || "",
      transactionId: checkFilter?.transactionId || "",
      orderStatusTwo: checkFilter?.orderStatusTwo || {
        label: "All Statuses",
        value: "all Statuses",
      },
      serviecNameOnly: checkFilter?.serviecNameOnly || [],
    };
    makeSetData[name] = data;
    localStorage.setItem("search_filter", JSON.stringify(makeSetData));
  };

  // useEffect(() => {
  //   let arr = backUpdata;
  //   if (cus_name) {
  //     let dataone = arr?.filter((w) => {
  //       return (
  //         w?.first_name?.toLowerCase().includes(cus_name?.toLowerCase()) ||
  //         w?.last_name?.toLowerCase().includes(cus_name?.toLowerCase()) ||
  //         w?.phone?.toLowerCase().includes(cus_name?.toLowerCase()) ||
  //         w?.email?.toLowerCase().includes(cus_name?.toLowerCase()) ||
  //         w?.user_id?.toLowerCase().includes(cus_name?.toLowerCase())
  //       );
  //     });
  //     arr = dataone;
  //   }

  //   if (orderId) {
  //     let datatwo = arr?.filter((w) => {
  //       return w._id.includes(orderId);
  //     });
  //     arr = datatwo;
  //   }

  //   if (transactionId) {
  //     let dataThree = arr?.filter((w) => {
  //       return w?.transaction_id?.includes(transactionId);
  //     });
  //     arr = dataThree;
  //   }
  //   if (serviecNameOnly) {
  //     let dataFour = arr?.filter((w) => {
  //       //service_name
  //       return (
  //         w?.order[0]?.service_name
  //           ?.toLowerCase()
  //           .includes(serviecNameOnly.toLowerCase()) ||
  //         w?.order[1]?.service_name
  //           ?.toLowerCase()
  //           .includes(serviecNameOnly.toLowerCase()) ||
  //         w?.order[2]?.service_name
  //           ?.toLowerCase()
  //           .includes(serviecNameOnly.toLowerCase())
  //       );
  //     });
  //     arr = dataFour;
  //   }

  //   if (orderStatusTwo) {
  //     let dataThree = arr?.filter((w) => {
  //       return (
  //         w?.order[0]?.order_status
  //           ?.toLowerCase()
  //           ?.includes(orderStatusTwo.toLowerCase()) ||
  //         w?.order[1]?.order_status
  //           ?.toLowerCase()
  //           ?.includes(orderStatusTwo.toLowerCase()) ||
  //         w?.order[2]?.order_status
  //           ?.toLowerCase()
  //           ?.includes(orderStatusTwo.toLowerCase())
  //       );
  //     });
  //     arr = dataThree;
  //   }
  //   setOrderData(arr);
  // }, [cus_name, orderId, transactionId, orderStatusTwo, serviecNameOnly]);


  // const [state, setState] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: 'selection',
  // })
  // const [opencaleder, setopencaleder] = useState(false);



  // function handleSelect(item) {
  //   setState(item.selection)
  //   // setopencaleder(false)
  // }




  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= startPage + 4 && currentPage + 1 <= totalPages) {
        setStartPage((prevStartPage) => prevStartPage + 1);
      }
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setStartPage(totalPages > 4 ? totalPages - 4 : 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (currentPage - 1 < startPage) {
        setStartPage((prevStartPage) => prevStartPage - 1);
      }
    }
  };

  const handlePreviousLastPage = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  const endPage = Math.min(startPage + 4, totalPages);


  function handleEvent(event, picker) {
    const { startDate, endDate } = picker;


  }
  function handleCallback(start, end, label) {
  }


  const isFutureDateDisabled = (currentDate) => {
    // Disable future dates
    return currentDate.isAfter();
  };


  const [err, setErr] = useState("")
  const handleApply = (event, picker) => {

    setCurrentPage(1)


    const startDate = picker.startDate;
    const endDate = picker.endDate;

    // Check if the selected date range includes future dates
    if (startDate.isSameOrAfter(moment()) || endDate.isSameOrAfter(moment())) {

      setErr("Please select a date range that doesn't include future dates.");
      picker.element.val('');
      setFromdate("")
      setTodate("")
      setTimeout(() => {
        setErr("");
      }, 2000);

    } else {
      setErr("");
      picker.element.val(`${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`);

      let sdate = moment(startDate).toDate();
      let edate = moment(endDate).toDate();
      setFromdate(moment(sdate).format('YYYY-MM-DD'));
      setTodate(moment(edate).format('YYYY-MM-DD'));
    }

  };



  const handleCancel = (event, picker) => {
    setCurrentPage(1)
    picker.element.val('');
    setFromdate("")
    setTodate("")
  };



  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="p-2">
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            // validation.handleSubmit();
            return false;
          }}
        >
          <div className="row">
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Order ID</Label>
              {/* <Input
                name="orderID"
                value={orderId || ""}
                className="form-control field-search"
                placeholder="Type Order Id"
                type="search"
                onChange={(e) => {
                  setOrderId(e.target.value);
                }}
              /> */}

              <DebounceInput
                debounceTimeout={300}
                minLength={2}
                maxLength={40}
                name="orderID"
                value={orderId || ""}
                className="form-control field-search"
                placeholder="Type Order Id"
                onChange={(e) => {
                  // handlehSetFilter("orderId", e.target.value);
                  setOrderId(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Order Status</Label>
              <Select
                placeholder={
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    All Statuses
                  </span>
                }
                value={orderStatus || null}
                onChange={(e) => {
                  setOrderStatus(e);
                  setOrderStatusTwo(e.value);
                  setCurrentPage(1);
                  // handlehSetFilter("orderStatusTwo", e);
                }}
                options={[
                  { label: "All Statuses", value: "" },
                  { label: "Verified", value: "verified" },
                  { label: "Partial", value: "partial" },
                  { label: "Pending", value: "pending" },
                  { label: "No match", value: "no match" },
                ]}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Date Range</Label>
              {/* <Input
                name="filterDate"
                className="form-control"
                placeholder="Type Something"
                type="date"
                onChange={(e) => {
                  const d = new Date(e.target.value);
                  let text = d.toISOString();
                  setOrderfilter({
                    ...orderfilter,
                    dateRange: text,
                  });
                }}
              /> */}

              {/* <DateRangePicker
                onChange={(w) => {
                  setValueDatePicker(w);
                  const one = w[0].toISOString();
                  const two = w[1].toISOString();
                  setOrderfilter({
                    ...orderfilter,
                    to: one,
                    from: two,
                  });
                  let fild = {
                    to: one,
                    from: two,
                  };
                  handlehSetFilter("orderfilter", w);
                  handlehSetFilter("orderfiltert", fild);
                }}
                value={valueDatePicker}
                maxDate={new Date()}
                rangeDivider=" - "
                format="dd / MM / yyyy "
                clearIcon={null}
                className="datePicker2"
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
              /> */}

              {/* <DebounceInput
              maxLength={40}
              readOnly={true}
                debounceTimeout={300}
                minLength={2}
                name="orderID"
                value={fromdate&&todate ? moment(fromdate).format("MMM DD, YYYY") +" - "+ moment(todate).format("MMM DD, YYYY"):"MM DD, YYYY - MM DD, YYYY"}
                onClick={()=>setopencaleder(!opencaleder)}
                className="form-control field-search"
                placeholder="MM DD,YYYY - MM DD,YYYY"
                autoComplete="off"
              />


{opencaleder && <> <div className="date-pick-div">
<DateRangePicker
  onChange={item => handleSelect(item)}
  showSelectionPreview={false}
  // moveRangeOnFirstSelection={false}
  ranges={[state]}
  direction="horizontal"
  // maxDate={new Date()}
  className={"date-range custom-dp"}
/> 
<div className="apply-date-btn-sec">
<button  className="btn apply-date-btn clear-date-btn mr-3" onClick={()=>{setopencaleder(false);setFromdate('');setTodate('')}}>Clear</button> 
<button  className="btn apply-date-btn btn-primary mr-3" onClick={()=>{setopencaleder(false);setFromdate(state.startDate);setTodate(state.endDate)}}>Apply</button> 
</div>
</div>
</> } */}

              <DateRangePicker
                onEvent={handleEvent}
                onCallback={handleCallback}
                isInvalidDate={isFutureDateDisabled}
                initialSettings={{
                  showDropdowns: true,
                  autoUpdateInput: false,
                  locale: {
                    cancelLabel: "Clear",
                  },
                  maxDate: new Date(),
                }}
                onApply={handleApply}
                onCancel={handleCancel}
              >
                <input
                  className="form-control field-search"
                  placeholder="Select Range"
                  readOnly
                />
              </DateRangePicker>
            </div>
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Customer User Id</Label>
              {/* <Input
                name="filterCustomer"
                value={cus_name || ""}
                className="form-control field-search"
                placeholder="By Customer Name "
                type="search"
                onChange={(e) => {
                  setCusName(e.target.value);
                }}
              /> */}

              <DebounceInput
                debounceTimeout={300}
                minLength={2}
                maxLength={40}
                name="filterCustomer"
                value={cus_name || ""}
                className="form-control field-search"
                placeholder="By Customer Name "
                type="search"
                onChange={(e) => {
                  setCusName(e.target.value);
                  setCurrentPage(1);
                  // handlehSetFilter("cus_name", e.target.value);
                }}
              />
            </div>
            {/* <div className="col-4 mb-3 multiSelect2">
              <Label className="form-label">Filter by Service</Label>
              <ReactMultiSelectCheckboxes
                options={optionData}
                value={serviecNameOnly}
                onChange={(e) => {
                  setServiceNameOnly(e);
                  // handlehSetFilter("serviecNameOnly", e);

                  setCheckMargin("165px");
                }}
                onFocus={() => {
                  setCheckMargin("165px");
                }}
                onBlur={() => {
                  setCheckMargin("0px");
                }}
              /> */}
            {/* <Multiselect
                options={optionData}
                selectedValues={optionData}
                displayValue="label"
                showCheckbox={true}
                onSelect={(e) => {
                  setRandomNo(Math.random());
                  setServiceNameOnly(e);
                }}
                onRemove={(e) => {
                  setRandomNo(Math.random());
                  setServiceNameOnly(e);
                }}
              /> */}
            {/* </div> */}
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Transaction ID</Label>
              {/* <Input
                name="transactionID"
                value={transactionId}
                className="form-control field-search"
                placeholder="Type Something"
                type="search"
                onChange={(e) => {
                  settransactionId(e.target.value);
                }}
              /> */}
              <DebounceInput
                maxLength={40}
                debounceTimeout={300}
                minLength={2}
                name="transactionID"
                value={transactionId}
                className="form-control field-search"
                placeholder="Type Transaction ID"
                type="search"
                onChange={(e) => {
                  settransactionId(e.target.value);
                  setCurrentPage(1);
                  // handlehSetFilter("transactionId", e.target.value);
                }}
              />
            </div>
            <div className="col-4 mb3">
              <Label className="form-label">&nbsp;</Label>
              <button
                className=" btn-primary d-block"
                style={{ padding: "13px" }}
                onClick={() => {
                  settransactionId("")
                  setCusName("")
                  setFromdate("")
                  setTodate("")
                  setOrderId("")
                  setOrderStatus("")
                  setOrderStatusTwo("")
                  handleCancel()
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </Form>
      </div>
      <Card>
        <CardBody>
          {orderData?.length == 0 && !loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                marginTop: checkmargin,
              }}
            >
              <h2>No Data Found</h2>
            </div>
          ) : (
            <>
              <div className="col-12 col-md-6 ">
                <div className="mb-3">
                  <Label className=" ">Records per page</Label>
                  <Select
                    value={{
                      label: `${pageSize} records`,
                      value: pageSize,
                    }}
                    onChange={handlePageSizeChange}
                    className="w-50"
                    options={[
                      { label: "5 records", value: 5 },
                      { label: "10 records", value: 10 },
                      { label: "20 records", value: 20 },
                      { label: "50 records", value: 50 },
                      { label: "100 records", value: 100 },
                    ]}
                  />
                </div>
              </div>
              {!loading ? (
                <>
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive orders-table-main-div"
                          id="harshu"
                        >
                          <BootstrapTable
                            keyField="id"
                            key={visibleActions}
                            data={data}
                            columns={EcommerceOrderColumns(toggle)}
                            responsive
                            bordered={false}
                            striped={false}
                            classes={
                              "table align-middle table-nowrap table-no-check table-check"
                            }
                            headerWrapperClasses={"table-light"}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="row align-items-center p-3">
                      <div className="col-12 col-md-6 text-start mb-2 mb-md-0">
                        Showing{" "}
                        {currentPage <= 1
                          ? 1
                          : (currentPage - 1) * pageSize + 1}{" "}
                        to {Math.min(currentPage * pageSize, countShow)} of{" "}
                        {countShow} entries
                      </div>
                      <div className="col-12 col-md-6 d-flex justify-content-md-end">

                      </div>
                    </div>
                    {/* Showing {currentPage <= 1 ? 1 : (currentPage - 1) * 10 + 1} to {currentPage * 10 <= countShow ? currentPage * 10 : countShow}  of {countShow} entries */}
                  </React.Fragment>
                  <div className="pagination pagination-rounded justify-content-center mt-4">
                    <button
                      onClick={handlePreviousLastPage}
                      disabled={currentPage === 1}
                      className={
                        currentPage === page
                          ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                          : "test btn  btn-circle btn-xl rounded-circle m-2"
                      }
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className={
                        currentPage === page
                          ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                          : "test btn  btn-circle btn-xl rounded-circle m-2"
                      }
                    >
                      {"<"}
                    </button>
                    {Array.from(
                      { length: endPage - startPage + 1 },
                      (_, index) => startPage + index
                    ).map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageClick(page)}
                        disabled={currentPage === page}
                        className={
                          currentPage === page
                            ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                            : "test btn btn-light  btn-xl rounded-circle m-2"
                        }
                      >
                        {page}
                      </button>
                    ))}
                    {totalPages > startPage + 4}
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      className={
                        currentPage === page
                          ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                          : "test btn  btn-circle btn-xl rounded-circle m-2"
                      }
                    >
                      {">"}
                    </button>
                    <button
                      onClick={handleLastPage}
                      disabled={currentPage === totalPages}
                      className={
                        currentPage === page
                          ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2"
                          : "test btn  btn-circle btn-xl rounded-circle m-2"
                      }
                    >
                      {">>"}
                    </button>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: checkmargin,
                  }}
                >
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                </div>
              )}

              {/* {loading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: checkmargin,
                  }}
                >
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                </div>
              ) : (
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  //  keyField="name"
                  // columns={EcommerceOrderColumns(toggle)}
                  // data={orderData || []}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="name"
                      data={orderData || []}
                      columns={EcommerceOrderColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="name"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-no-check table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="pagination pagination-rounded justify-content-center mt-4">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )} */}
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

ResultTable.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(ResultTable);
